<template>
  <div class="allmain">
    <div class="header">
      <div class="header_logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="header_center">
        <div @click="gohome">首页</div>
        <div @click="goproductIntroduction">产品介绍</div>
        <div @click="gocompanyprofile">公司简介</div>
      </div>
      <div class="header_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail_img">
      <img src="../../assets/detail_1.png" />
    </div>
    <div class="main_title">
      <div class="magin_titleData">成功案例</div>
      <div class="detail_text">
        <div>
          友莲果坊位于开福区，是一家经营面积20平米的社区水果店，我们7月份开始合作，先采集数据，8月份针对客群数据指标执行一个简单策略，
          直接让8月的业绩环比7月提升了50%。7月日均900元，8月日均提升到1400元。
        </div>
        <div>我们怎么做成的呢？</div>
        <div>
          通过过店客群的采集，我们发现这个社区的水果店在下午5点到7点有一个下班的高峰期。
          这波上班族的消费习惯是到店买个西瓜或买串葡萄，他们要求店主给它清洗或切块装盒带回去方便直接吃。
          店主按顾客的要求，差不多要10分钟才能服务好一个顾客，7月份下午5-7点这段时间，他们平时只能成交8-10个客人。
          所以基于这一点，我们让店家在下午的3点就准备好现切现洗的西瓜和葡萄，封装定价成20元一盒一盒的放在冷柜里，在5-7点这个下班高峰期出现的时候，一分钟之内就能完成一单。成交笔数从原来的8-10单提升至20单甚至30单，所以光这两个小时新增的业绩就差不多有400多元。<br />
          我们还发现在上午有退休老人和家庭主妇这一波的客群高峰，然后发现他们有占小便宜的习惯，所以这时候建议店主把那个每天晚上出清调整为第二天上午这一波高峰的时候半价，或者5块一堆3块一堆的这种方式把这波客人吸引到店消费，同时带动上午班的消费，让整个营销效率从1：2提升至1：5。<br />
          分群数据通过客流分群创造价值，重构以顾客为中心的门店经营。过店客群是线下门店的天然流量，也是门店的线下触点。
          我们提供两个赋能。客群数字化赋能和潜客增量赋能。<br />
          通过客群洞察，我们能够发现潜在客群与门店接触的时间，我们采取针对性营销策略，抓住这波过店客群的即时消费，快速转化为门店业绩。
        </div>
      </div>
    </div>
    <div class="main_case">
      <div class="main_case_bg">
        <div class="magin_titleData">合作前后</div>
        <div class="main_case_data">
          <div class="main_case_title">
            <div>成功案例————开福区友莲果坊</div>
            <div>7月采集数据，8月实施策略</div>
          </div>
          <div>
            <img src="../../assets/case_2.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_bottom">
      <div class="main_bottom_bg">
        <div class="main_bottom_apply">
          <div>
            <img src="../../assets/apply.png" />
          </div>
          <div>
            <span>填写入驻申请，专人联系为您服务！</span>
          </div>
          <div>
            <img src="../../assets/code.png" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_logo">
        <img src="../../assets/fqsj_2.png" />
      </div>
      <div class="footer_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat_on.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone_on.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023024436号</a>
      </div>
      <div>联系地址：开福区秀峰街道秀峰商贸城12栋2号101</div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  methods: {
    goApply() {
      this.$router.push({ name: "applicationSettlement" });
    },
    gohome() {
      this.$router.push({ name: "home" });
    },
    goproductIntroduction() {
      this.$router.push({ name: "productIntroduction" });
    },
    gocompanyprofile() {
      this.$router.push({ name: "companyprofile" });
    },
  },
};
</script>
  
  <style scoped>
.allmain {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.header {
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right div {
  margin: 0 10px;
}
.header_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header_center {
  width: 60%;
  display: flex;
}
.header_center div {
  margin: 0px 50px;
}
.main img {
  width: 100%;
}
.main_title {
  text-align: left;
  margin: 120px 15%;
}
.main_cardList {
  display: flex;
}
.main_card {
  width: 25%;
  padding: 70px 15px 20px 15px;
  margin: 10px;
}
.main_card div:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 20px;
}

.main_card div:nth-child(3) {
  font-size: 14px;
  color: #777777;
  text-align: left;
}
.magin_titleData {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 80px;
}
.main_case {
  width: 100%;
  background: url("../../assets/case_1.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 40px;
}
.main_case_bg {
  text-align: center;
  padding: 50px 15%;
}
.main_case_data {
  width: 100%;
  background-color: white;
  position: relative;
}
.main_case_data img {
  width: 100%;
  height: 100%;
}
.main_case_title {
  width: 100%;
  position: absolute;
  top: 60px;
  display: flex;
}
.main_case_title div:nth-child(1) {
  margin-left: 50px;
  color: #2963ff;
  font-size: 24px;
  font-weight: 700;
}
.main_case_title div:nth-child(2) {
  color: #111111;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 14px;
}
.main_case_title div:nth-child(3) {
  color: #777777;
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 18px;
}
.main_bottom {
  width: 100%;
  background: url("../../assets/FQSJ_logo.png") no-repeat;
  background-size: 100% 100%;
}
.main_bottom_bg {
  text-align: center;
  height: 320px;
}
.main_bottom_apply div:nth-child(1) {
  padding: 80px 0px 20px 0px;
}
.main_bottom_apply div:nth-child(2) {
  color: #777777;
  font-size: 14px;
  padding-bottom: 20px;
}
.main_bottom_apply .el-button {
  width: 160px;
  height: 42px;
  color: white;
  background-color: #2558e4;
  border-radius: 10px;
}
.footer {
  background-color: #111111;
  color: white;
  height: 100px;
  padding: 0px 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_right {
  display: flex;
  align-items: center;
}
.footer_right div {
  margin: 0 10px;
}
.footer_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.record {
  height: 80px;
  color: white;
  padding: 0 10%;
  background-color: #3d3d3d;
  width: 80%;
  line-height: 40px;
}
.record div {
  margin: 0px 10px;
}
.detail_img img {
  width: 100%;
}
.detail_text {
  font-size: 18px;
  color: #3d3d3d;
  line-height: 45px;
}
.detail_text div:nth-child(2) {
  margin: 20px 0px;
  color: #2558e4;
  font-weight: 700;
}

.record a {
  color: white;
  text-decoration: none;
}

</style>

  